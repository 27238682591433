@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181c32;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.c-line::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
}

.about-us {
  background-image: url('/public/images/about-us/about-us.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%; /* Membuat tinggi body menjadi full height */
}

/* .markdown > * {
  all: revert;
} */

div.jfFaHg svg {
  display: none !important;
}

select {
  text-transform: capitalize;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer base {
  :root {
    /*------Color variables------*/
    --black_900: #040404;
    --black_900_01: #000000;
    --blue_200: #9fc8ff;
    --blue_gray_100_99: #d2dddc99;
    --blue_gray_100_b2: #cfdbd5b2;
    --blue_gray_900: #232632;
    --blue_gray_900_01: #263238;
    --deep_orange_100: #ffc3bd;
    --deep_orange_500: #ff4b26;
    --gray_100: #f5f5f5;
    --gray_400: #c4c4c4;
    --gray_900: #111111;
    --gray_900_19: #11111119;
    --grayscale_gainsboro: #f8f8f8;
    --grayscale_gray: #a4a4a9;
    --grayscale_white_0: #ffffff;
    --grayscale_white_1: #ffffff3f;
    --indigo_400: #7474b0;
    --orange_400: #feaa2d;
    --others_lightgray: #d2dddc;
    --others_limegreen: #2ac154;
    --others_salmon: #fd7871;
    --primary_primary: #16132607;
    --primary_200: #8AC8FF;
    --purple_100: #e5caff;
    --red_50: #ffebeb;
    --red_900: #a02724;
    --red_a100: #ff8b7b;
    --red_a100_01: #ed847e;
    --red_a200: #fc4e4e;
    --red_a200_01: #ff5652;
    --secondary_khaki: #ecff79;
    --secondary_lightskyblue: #afc0ff;
    --secondary_orchid: #c388f7;
    --secondary_yellowgreen: #c0faa0;
    --text_color_black: #161326;
    --text_color_gray_dark: #6d6d6d;
    --white_a700_99: #ffffff99;
    --white_a700_cc: #ffffffcc;

    /*------Border radius variables------*/
    --radius-xs: 1px;
    --radius-sm: 2px;
    --radius-md: 4px;
    --radius-lg: 5px;
    --radius-xl: 6px;
    --radius-2xl: 8px;
    --radius-3xl: 10px;
    --radius-4xl: 12px;
    --radius-5xl: 14px;
    --radius-6xl: 16px;
    --radius-7xl: 20px;

    /*------Spacing variables------*/
    --space-xs: 0.13rem;
    --space-sm: 0.25rem;
    --space-md: 0.38rem;
    --space-lg: 0.44rem;
    --space-xl: 0.5rem;
    --space-2xl: 0.56rem;
    --space-3xl: 0.63rem;
    --space-4xl: 0.75rem;
    --space-5xl: 0.88rem;
    --space-6xl: 1rem;
    --space-7xl: 1.13rem;
    --space-8xl: 1.25rem;
    --space-9xl: 1.38rem;
    --space-10xl: 1.5rem;
    --space-11xl: 1.63rem;
    --space-12xl: 1.75rem;
    --space-13xl: 1.88rem;
    --space-14xl: 2rem;
    --space-15xl: 2.5rem;
  }
}